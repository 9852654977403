import axios from "axios";

const getCompanyDeleteData = (ID) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_GET_COMPANY_DELETE_DATA, { params: { ID } })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default getCompanyDeleteData;
