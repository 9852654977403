import axios from "axios";

const deleteCompany = (ID) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(process.env.REACT_APP_DELETE_COMPANY, { params: { ID } })
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};
export default deleteCompany;
