import React, { useEffect, useState } from "react";
import "../Assets/Styles/Components/Header.css";
import { useCookies } from "react-cookie";

import { BsJustify as HamburgerIcon } from "react-icons/bs";
import { BsSearch as SearchIcon } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";

const Header = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["odkrijudata"]);
  const [loginRequired, setLoginRequired] = useState(false);

  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    if (!cookies?.odkrijudata) {
      setLoginRequired(true);
    }
  }, [cookies?.odkrijudata]);

  const handleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  useEffect(() => {}, [loginRequired]);
  return (
    <>
      {showSidebar && (
        <Sidebar
          showsidebar={showSidebar}
          closesidebar={handleSidebar}
          logginrequired={loginRequired}
          user={cookies?.odkrijudata}
        />
      )}
      <div className="header-box">
        <span onClick={() => navigate("/")} className="logo-box">
          <img alt="Logotip podjetja odkrij.si" src="/odkrijLogoSmall.png" />
        </span>
        <span className="searchbar-box">
          <input className="searchbar" type="text" placeholder="Odkrij..." />
          <SearchIcon className="search-icon" />
        </span>
        <span className="login-info-box">
          <Link className="header-link" to={"/prijavaUporabnika"}>
            {loginRequired
              ? "Prijava"
              : `${cookies?.odkrijudata?.firstName} ${cookies?.odkrijudata?.lastName}`}
          </Link>
        </span>
        <span className="hamburger-menu-box">
          <HamburgerIcon
            className="open-hamburger-icon"
            onClick={handleSidebar}
          />
        </span>
      </div>
    </>
  );
};

export default Header;
