import axios from "axios";

const requestNewVerificationCode = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_REQUEST_NEW_VERIFICATION_CODE, {
        params: { email },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export default requestNewVerificationCode;
