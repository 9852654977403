import axios from "axios";

const getPopularCompaniesArr = (filters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_GET_POPULAR_COMPANIES, {
        params: { filters },
      })
      .catch((err) => reject(err))
      .then((res) => resolve(res));
  });
};

export default getPopularCompaniesArr;
