import React from "react";
import "../Assets/Styles/Pages/PrivacyPolicy.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className="privacy-policy-page">
        <span className="privacy-policy-title-main">Politika zasebnosti</span>
        <div className="text-box">
          <span className="privacy-policy-title">Upravitelj podatkov: </span>
          <span className="text">
            Družba _________ d.o.o., Matična številka: 1234561000 (v
            nadaljevanju "Odkrij.si"), posveča posebno pozornost varstvu osebnih
            podatkov. V nadaljevanju lahko preberete več o tem, kako
            zagotavljamo varstvo vaših podatkov in preprečujemo njihovo zlorabo.
          </span>
        </div>

        <div className="text-box">
          <span className="privacy-policy-title">
            Katere podatke obdelujemo in zbiramo:
          </span>
          <span className="text">
            Med obiskom naše spletne strani zbiramo in obdelujemo podatke o
            obiskanih straneh, oddanih naročilih, vrsti naprave, preko katere
            dostopate do strani, ter podatke o času obiska. Če se registrirate,
            hranimo vaše ime, priimek, e-naslov in geslo. Ob prijavi podjetja
            hranimo naslednje podatke: Kratko in polno ime podjetja, Naslov
            podjetja, Kontaktne podatke (e-naslov, telefonska številka), Podatke
            o predstavniku podjetja (ime, priimek, e-naslov), Pravna oblika
            podjetja, matična številka, davčna številka, številka bančnega
            računa, Kategorija in opis podjetja. Če dodate izdelek ali storitev,
            hranimo podatke o imenu izdelka, ceni, kontaktnem e-naslovu, času
            dostave, kategoriji, vrsti, opisu, garanciji, pogojih garancije,
            vračilu in pogojih za vračilo. Te podatke uporabljamo za prikaz na
            spletnem mestu in za statistične analize. Kontaktne podatke
            uporabljamo tudi za pošiljanje računov, novic in ponudb, vendar le,
            če v to izrecno privolite.
          </span>
        </div>

        <div className="text-box">
          <span className="privacy-policy-title">
            Komu posredujemo podatke:
          </span>
          <span className="text">
            Vaših osebnih podatkov ne posredujemo tretjim osebam, podjetjem ali
            organizacijam. Podatke uporabljamo izključno znotraj sistema
            "Odkrij.si".
          </span>
        </div>

        <div className="text-box">
          <span className="privacy-policy-title">Čas hrambe podatkov:</span>
          <span className="text">
            Vaši podatki se hranijo do zaključka vašega obiska na naši spletni
            strani ali do preklica naročnine na storitve podjetja.
          </span>
        </div>

        <div className="text-box">
          <span className="privacy-policy-title">Vaše pravice:</span>
          <span className="text">
            V zvezi z obdelavo vaših osebnih podatkov imate naslednje pravice:
            Pravica do popravka: Če so vaši osebni podatki netočni ali
            nepopolni, imate pravico do njihovega popravka, kar lahko storite
            tudi samostojno. Pravica do dostopa: Če ne morete dostopati do
            svojih osebnih podatkov, imate pravico zahtevati vpogled v te
            podatke.
          </span>
        </div>

        <div className="text-box">
          <span className="privacy-policy-title">
            Kontakt za več informacij:
          </span>
          <span className="text">
            Za dodatne informacije in uveljavitev vaših pravic nas lahko
            kontaktirate na naslovu:{" "}
            <a className="mail-link" href="mailto:info@odkrij.si">
              info@odkrij.si
            </a>
          </span>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
