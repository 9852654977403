import React from "react";
import InputBox from "./InputBox";
import DropDownMenu from "./DropDownMenu";
import ImageInput from "./ImageInput";
import TextArea from "./TextArea";

import { BsXLg as CloseIcon } from "react-icons/bs";

import "../Assets/Styles/Components/AddService.css";
import CheckBox from "./CheckBox";

const AddService = (props) => {
  return (
    <div className="add-service-page">
      <div className="add-service-box">
        <span>
          <CloseIcon
            onClick={() => {
              if (props.closewindow) {
                props.closewindow();
              } else {
                props.goback();
              }
            }}
            className="close-icon"
          />
        </span>
        <InputBox
          label="Ime storitve:"
          id="input-bar-1"
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container-center"
          name="name"
          type="text"
          datatype="basic"
          placeholder="Vnesi ime storitve..."
          onchange={props.changeofferinput}
        />
        <InputBox
          label="Cena storitve:"
          id="input-bar-2"
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container-center"
          name="price"
          type="text"
          datatype="basic"
          placeholder="Vnesi ceno storitve..."
          onchange={props.changeofferinput}
        />
        <InputBox
          label="E-naslov za info:"
          id="input-bar-3"
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container-center"
          name="email"
          type="text"
          datatype="basic"
          placeholder="Vnesi e-naslov..."
          onchange={props.changeofferinput}
        />
        <InputBox
          label="Čas čakalne vrste:"
          id="input-bar-4"
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container-center"
          name="time"
          type="text"
          datatype="basic"
          placeholder="Vnesi čas čakanja na storitev..."
          onchange={props.changeofferinput}
        />
        <DropDownMenu
          label="Kategorija storitve:"
          placeholder="kategorija"
          id="dropdown-1"
          dropdownbox="dropdown-box-center"
          name="category"
          datatype="basic"
          onchange={props.changeofferinput}
          data={[
            { value: 21, text: "Vodovodne storitve" },
            { value: 22, text: "Elektro storitve" },
            { value: 23, text: "Lesne storitve" },
            { value: 24, text: "Selitvene storitve" },
            { value: 25, text: "Čiščenje" },
            { value: 26, text: "Gradbene storitve" },
            { value: 27, text: "Vzdrževalne storitve" },
            { value: 28, text: "Avtomehanične storitve" },
            { value: 29, text: "Avtokleparske storitve" },
            { value: 30, text: "Avtoličarske storitve" },
            { value: 31, text: "Prevozniške storitve" },
            { value: 32, text: "Gradbeno svetovanje in načrtovanje" },
            { value: 33, text: "Poslovno svetovanje" },
            { value: 34, text: "Finančno svetovanje" },
            { value: 35, text: "Pravno svetovanje" },
            { value: 36, text: "Zavarovalniško svetovanje" },
            { value: 37, text: "IT svetovanje" },
            { value: 38, text: "Svetovanje za kibernetsko varnost" },
            { value: 39, text: "Spletno oblikovanje in razvoj" },
            { value: 40, text: "Upravljanje družbenih omrežij" },
            { value: 41, text: "Digitalno oglaševanje" },
            { value: 42, text: "Tiskarske storitve" },
            { value: 43, text: "Organizacija dogodkov" },
            { value: 44, text: "Fotografske storitve" },
            { value: 45, text: "Video produkcija" },
            { value: 46, text: "Prevajanje in prepisovanje" },
            { value: 47, text: "Računovodske storitve" },
            { value: 48, text: "Kadrovske storitve in zaposlovanje" },
            { value: 49, text: "Osebno usposabljanje (coaching)" },
            { value: 50, text: "Gostinske storitve (catering)" },
            { value: 51, text: "Frizerkse in kozmetične storitve" },
            { value: 52, text: "Masažne storitve" },
            { value: 53, text: "Fitnes storitve" },
            { value: 54, text: "Individualno zdravstveno svetovanje" },
            { value: 55, text: "Rehabilitacijske storitve" },
          ]}
        />
        <InputBox
          label="Vrsta storitve:"
          id="input-bar-5"
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container-center"
          name="type"
          type="text"
          datatype="basic"
          placeholder="Vnesi vrsto storitve..."
          onchange={props.changeofferinput}
        />
        <TextArea
          label="Opis storitve"
          id="textarea"
          name="text"
          rows="5"
          cols="20"
          placeholder="Vnesi opis storitve..."
          datatype="basic"
          char={props.charcount}
          minChar="200"
          labelclass="label"
          onchange={props.changeofferinput}
        />
        <CheckBox
          label="Naročilo izdelka:"
          id="checkbox-1"
          name="orderItem"
          labelclass="label"
          value="true"
          datatype="flags"
          ischecked={props.ischeckedorderitem}
          onchange={props.changeofferinput}
        />
        {props.imagename && (
          <div className="add-service-image-box">
            <ImageInput
              datatype="image"
              name={`${props.imagename}-service`}
              src=""
              classname="loading-image"
              title="Slika storitve (slika ni v velikosti kot bo na strani)"
              id="input-img-1"
              onchange={props.changeofferinput}
              alt="Slika storitve podjetja"
              width="80%"
              height="250px"
              nextbtn="Shrani sliko"
              nextimg={true}
            />
          </div>
        )}
        <button
          className="button-1"
          type="button"
          onClick={() => {
            props.addoffer(props.offertype);
            if (props.closewindow) {
              props.closewindow();
            }
          }}
        >
          Dodaj
        </button>
      </div>
    </div>
  );
};

export default AddService;
