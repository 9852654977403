import axios from "axios";

const getCompanyData = (params) => {
    return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_GET_COMPANY_DATA_BY_ID, { params: { params } })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default getCompanyData;
