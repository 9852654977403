import React, { useEffect, useRef, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ErrorWindow from "../Components/ErrorWindow";
import Loader from "../Components/Loader";
import UpdateOfferWindow from "../Components/UpdateOfferWindow";
import ValidateSingleOffer from "../Components/utils/ValidateSingleOffer";
import {
  DIACRITICS,
  NUMBER_REGEX,
  SPECIAL_CHARS,
} from "../Constants/RegexPatterns";
import getEditOfferData from "../Services/getEditOfferData";
import getOfferData from "../Services/getOfferData";
import updateOfferData from "../Services/updateOfferData";
import insertCompanyImages from "../Services/insertCompanyImages";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";
import offerCategoryParser from "../Components/utils/OfferCategoryParser";

import "../Assets/Styles/Pages/EditOffer.css";

import {
  BsBoxFill as ProductIcon,
  BsWrench as ServiceIcon,
} from "react-icons/bs";

const EditOffer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) {
      navigate("/upravljanjePodjetja");
      return;
    }

    const { companyIds, offerType } = location.state;

    if (!companyIds) {
      navigate("/upravljanjePodjetja");
      return;
    }

    for (const id of companyIds) {
      if (!NUMBER_REGEX.test(id)) {
        navigate("/upravljanjePodjetja");
        return;
      }
    }

    if (!offerType) {
      navigate("/upravlanjePodjetja");
      return;
    }

    if (offerType !== "product" && offerType !== "service") {
      navigate("/upravlanjePodjetja");
      return;
    }

    setCompanyIds(companyIds);
    setOfferType(offerType);
  }, []);

  const [companyIds, setCompanyIds] = useState([]);
  const [offerType, setOfferType] = useState(undefined);

  const [offerArr, setOfferArr] = useState([]);

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [errorTitle, setErrorTitle] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [isOpenUpdateOfferWindow, setIsOpenUpdateOfferWindow] = useState(false);

  const [offerObj, setOfferObj] = useState(null);

  const [offersToSave, setOffersToSave] = useState([]);

  const formData = useRef(new FormData());

  useEffect(() => {
    setIsLoading(true);
    if (companyIds.length > 0) {
      getOfferDataFunc();
    }

    setIsLoading(false);
  }, [companyIds]);

  const getOfferDataFunc = async () => {
    setIsLoading(true);

    try {
      const response = await getEditOfferData(companyIds, offerType);
      if (response.status === 200) {
        setOfferArr(response.data.offerArr);
        setIsLoading(false);
      }
    } catch (err) {
      setError(true);
      setErrorText(err?.response?.data?.message);
      setErrorTitle(ErrorCodesParser(err?.response?.data?.errorCode));
    }
  };

  const handleErrorWindow = () => {
    setError(!error);
  };

  const handleUpdateOfferWindow = async (offerId) => {
    if (!isOpenUpdateOfferWindow && offerId) {
      let offerFound = false;

      for (const offer of offersToSave) {
        if (offer.basic.ID === offerId) {
          offerFound = true;
          setOfferObj(offer);
        }
      }

      if (!offerFound) {
        try {
          setIsLoading(true);
          const response = await getOfferData(offerId);
          setOfferObj(response.data.offer);
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
          setError(true);
          setErrorText(err?.response?.data?.message);
          setErrorTitle(ErrorCodesParser(err?.response?.data?.errorCode));
        }
      }
    }
    setIsOpenUpdateOfferWindow(!isOpenUpdateOfferWindow);
  };

  const saveOffer = async (
    newOffer,
    newImage,
    newImageUrl,
    offerType,
    oldOfferName,
    companyId
  ) => {
    const { errorFound, errorText, errorTitle } = ValidateSingleOffer(newOffer);

    if (errorFound) {
      setError(errorFound);
      setErrorTitle(errorTitle);
      setErrorText(errorText);
      return;
    }

    if (newOffer.basic.name !== oldOfferName) {
      const images = formData.current.getAll("images");
      const oldImageName = `${companyId}-${oldOfferName
        .toLowerCase()
        .replace(DIACRITICS, "")
        .replace(SPECIAL_CHARS, "")}-${offerType}`;
      const newImageName = `${companyId}-${newOffer.basic.name
        .toLowerCase()
        .replace(DIACRITICS, "")
        .replace(SPECIAL_CHARS, "")}-${offerType}`;

      let imageFound = false;

      const newimagesArr = images.map((image, index) => {
        if (image.name === oldImageName) {
          const tempFile = new File([image], newImageName, {
            type: image.type,
            lastModified: new Date(),
          });
          imageFound = true;
          return tempFile;
        }
        return image;
      });

      formData.current.delete("images");
      for (const image of newimagesArr) {
        formData.current.append("images", image);
      }

      if (!imageFound && !newImage) {
        await fetch(
          `${process.env.REACT_APP_GET_OFFER_IMAGES}${companyId}-${oldOfferName
            .toLowerCase()
            .replace(DIACRITICS, "")
            .replace(SPECIAL_CHARS, "")}-${offerType}.png`
        )
          .then((res) => {
            return res.blob();
          })
          .then((blob) => {
            const file = new File([blob], newImageName, { type: blob.type });
            formData.current.append("images", file);
          });
      }

      if (!imageFound && newImage) {
        const imageTemp = newImageUrl.get("images");
        const image = new File([imageTemp], newImageName, {
          type: imageTemp.type,
        });
        formData.current.append("images", image);
      }
    }

    if (newImage) {
      const images = formData.current.getAll("images");

      const oldImageName = `${companyId}-${oldOfferName
        .toLowerCase()
        .replace(DIACRITICS, "")
        .replace(SPECIAL_CHARS, "")}-${offerType}`;

      const newimagesArr = images.map((image, index) => {
        if (image.name === oldImageName) {
          return newImageUrl.get("images");
        } else {
          return image;
        }
      });

      formData.current.delete("images");

      if (newimagesArr.length > 0) {
        for (const image of newimagesArr) {
          formData.current.append("images", image);
        }
      } else {
        formData.current.append("images", newImageUrl.get("images"));
      }
    }

    const { ID, name, price, type, time, category } = newOffer.basic;

    const tempOffer = {
      ID,
      name,
      price,
      type,
      time,
      category,
      companyId,
    };

    const newOfferArr = [];

    for (const itemOfArray of offerArr) {
      if (itemOfArray.ID === tempOffer.ID) {
        newOfferArr.push(tempOffer);
      } else {
        newOfferArr.push(itemOfArray);
      }
    }

    const newOfferToSaveArr = [];

    let foundOffer = false;

    for (const itemOfArray of offersToSave) {
      if (itemOfArray.basic.ID === newOffer.basic.ID) {
        foundOffer = true;
        newOfferToSaveArr.push(newOffer);
      } else {
        newOfferToSaveArr.push(itemOfArray);
      }
    }

    if (!foundOffer) {
      newOfferToSaveArr.push(newOffer);
    }

    setOffersToSave(newOfferToSaveArr);
    setOfferArr(newOfferArr);

    setIsOpenUpdateOfferWindow(!isOpenUpdateOfferWindow);
  };

  const updateOffer = async () => {
    if (offersToSave.length === 0) {
      navigate(-1);
    }

    try {
      //TODO: validiraj podatke
      setIsLoading(true);
      const response = await updateOfferData(offersToSave);
      if (response.status === 200) {
        await insertCompanyImages(formData.current);
        setIsLoading(false);
        navigate(-1);
      }
    } catch (err) {
      setIsLoading(false);
      setError(true);
      setErrorText(err?.response?.data?.message);
      setErrorTitle(ErrorCodesParser(err?.response?.data?.errorCode));
    }
  };

  // #region JSX
  return (
    <>
      {error && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={handleErrorWindow}
          btntext={"RAZUMEM"}
        />
      )}
      {isLoading && <Loader />}
      <div className="offer-list-container">
        {offerArr &&
          offerArr.map((offer, key) => (
            <div
              key={key}
              className="offer-list-item-box"
              onClick={() => {
                if (!isOpenUpdateOfferWindow) {
                  handleUpdateOfferWindow(offer.ID);
                }
              }}
            >
              {offerType === "product" ? (
                <ProductIcon className="offer-list-icon" />
              ) : (
                <ServiceIcon className="offer-list-icon" />
              )}
              <span>{offer.name}</span>
              <span>{offer.price}</span>
              <span>{offer.type}</span>
              <span>{offerCategoryParser(offer.category)}</span>
              <span>{offer.time}</span>

              {isOpenUpdateOfferWindow && offer.ID === offerObj.basic.ID && (
                <UpdateOfferWindow
                  companyid={offer.companyId}
                  offer={offerObj}
                  images={
                    imageObjExsists(
                      offer.companyId,
                      offer.name,
                      offerType,
                      formData.current
                    )
                      ? formData
                      : false
                  }
                  imageurl={
                    !imageObjExsists(
                      offer.companyId,
                      offer.name,
                      offerType,
                      formData.current
                    )
                      ? `${process.env.REACT_APP_OFFER_IMAGES_URL}${
                          offer.companyId
                        }-${offer.name
                          .toLowerCase()
                          .replace(DIACRITICS, "")
                          .replace(SPECIAL_CHARS, "")}-${offerType}.png`
                      : false
                  }
                  offertype={offerType}
                  saveoffer={saveOffer}
                  handleupdateofferwindow={() =>
                    handleUpdateOfferWindow(offer.ID)
                  }
                />
              )}
            </div>
          ))}
        <button className="button-1" onClick={updateOffer}>
          Shrani Ponudbo
        </button>
      </div>
    </>
  );
};

export default EditOffer;

const imageObjExsists = (companyId, offerName, offerType, formData) => {
  const images = formData.getAll("images");

  const imageName = `${companyId}-${offerName
    .toLowerCase()
    .replace(DIACRITICS, "")
    .replace(SPECIAL_CHARS, "")}-${offerType}`;

  for (const image of images) {
    if (image.name === imageName) {
      return true;
    }
  }

  return false;
};
