import axios from "axios";

const renameCompanyImages = (oldName, newName) => {
  return new Promise((resolve, reject) => {
    axios
      .put(process.env.REACT_APP_UPDATE_IMAGE_NAME, { oldName, newName })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default renameCompanyImages;
