import axios from "axios";

const updateCompanyData = (company, returnNewData) => {
  return new Promise((resolve, reject) => {
    axios
      .put(process.env.REACT_APP_UPDATE_COMPANY_DATA, {
        company,
        returnNewData,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default updateCompanyData;
