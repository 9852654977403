import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import getCompanyData from "../Services/getCompanyData";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";
import ErrorWindow from "../Components/ErrorWindow";
import Loader from "../Components/Loader";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Company from "./Company";

const CompanyViaUrl = () => {
  const location = useLocation();

  const [company, setCompany] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");

  // #region GET COMPANY
  const getCompany = async () => {
    try {
      setIsLoading(true);

      const response = await getCompanyData(location.state.ID);

      const { company } = response.data;

      setCompany(company);
    } catch (err) {
      setError(true);
      if (!err.response) {
        setIsLoading(false);
        setErrorText(
          "Pri nalaganju podatkov je prišlo do napake poskusite znova ali pa se obrnite na našo podporno ekipo!"
        );
        setErrorTitle("NAPAKA!");
      } else {
        setIsLoading(false);
        setErrorText(err?.response?.data?.message);
        setErrorTitle(ErrorCodesParser(err?.response.data?.errorCode));
      }
    } finally {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsLoading(false);
    }
  };

  // #region ON LOAD
  useEffect(() => {
    //TODO: Na podlagi url-ja naloži podjetje
    getCompany();
  }, []);

  // #region Handle Error Window
  const handleErrorWindow = () => {
    setError(false);
  };

  // #region JSX
  return (
    <>
      {error && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={handleErrorWindow}
          btntext={"RAZUMEM"}
        />
      )}

      <Header />

      {isLoading && !company && !error && <Loader />}

      {company ? <Company company={company} /> : <Loader />}

      <Footer />
    </>
  );
};

export default CompanyViaUrl;
