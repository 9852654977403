import axios from "axios";

const getCompaniesData = (filters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_GET_COMPANIES_DATA, {
        params: { filters },
      })
      .catch((err) => reject(err))
      .then((res) => resolve(res));
  });
};

export default getCompaniesData;
