import React, { useEffect, useRef, useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import ErrorWindow from "../Components/ErrorWindow";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";
import InputBox from "../Components/InputBox";
import getUsersData from "../Services/getUsersData";
import "../Assets/Styles/Pages/UserAdministration.css";
import forgottenPasswordApi from "../Services/forgottenPassword";
import changePasswordApi from "../Services/changePassword";
import changeUsersDataApi from "../Services/changeUsersData";

import { BsPersonCircle as ProfileIcon } from "react-icons/bs";
import { PASSWORD_REGEX } from "../Constants/RegexPatterns";
import VerifyEmail from "../Components/VerifyEmail";
import Loader from "../Components/Loader";
import ValidateChangeUserData from "../Components/utils/ValidateChangeUserData";

const UserAdministration = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["odkrijudata"]);

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [oldUser, setOldUser] = useState({});
  const [user, setUser] = useState({
    basic: {},
  });

  const [userPassword, setUserPassword] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showForgotenPassword, setShowForgotenPassword] = useState(false);

  const [forgottenPassword, setForgotenPasswrod] = useState({
    newPassword: "",
  });

  const [openEnterCodeWindow, setOpenEnterCodeWindow] = useState(false);

  // #region handle Code window
  const handleCodeWindow = () => {
    setOpenEnterCodeWindow(!openEnterCodeWindow);
  };

  const [mailEmail, setMailEmail] = useState("");

  // #region Onload
  useEffect(() => {
    setIsLoading(true);
    getOldUsersData();
  }, []);

  // #region GET OLD USERS DATA
  const getOldUsersData = async () => {
    try {
      const response = await getUsersData();
      setOldUser(response.data.user);
      setIsLoading(false);
    } catch (error) {
      setError(true);
      if (!error.response) {
        setErrorText(
          "Pri nalaganju podatkov je prišlo do napake poskusite znova ali pa se obrnite na našo podporno ekipo!"
        );
        setErrorTitle("NAPAKA!");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setError(true);
        setErrorText(error?.response?.data?.message);
        setErrorTitle(ErrorCodesParser(error?.response.data?.errorCode));
      }
    }
  };

  // #region handle err window
  const handleErrorWindow = () => {
    setError(!error);
    if (errorTitle === "POTREBNA PRIJAVA") {
      navigate("/prijavaUporabnika");
    }
  };

  // #region Hanlde input change
  const handleInputChange = (e, datatype) => {
    if (datatype === "forgottenPassword") {
      setForgotenPasswrod({
        ...forgottenPassword,
        [e.target.name]: e.target.value,
      });
      return;
    }

    if (datatype === "password") {
      setUserPassword({
        ...userPassword,
        [e.target.name]: e.target.value,
      });
      return;
    }

    setUser({
      ...user,
      [datatype]: { ...user[datatype], [e.target.name]: e.target.value },
    });
  };

  // #region Submit user data
  const changeUsersData = async () => {
    const { errorFound, errorText, errorTitle } = ValidateChangeUserData(user);
    setError(errorFound);
    setErrorText(errorText);
    setErrorTitle(errorTitle);

    if (!errorFound) {
      try {
        console.log("Test 1");
        const response = await changeUsersDataApi(user);
        console.log(response);
        if (response.data.status) {
          window.location.reload();
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      } catch (error) {
        if (!error.response) {
          setErrorText(
            "Pri nalaganju podatkov je prišlo do napake poskusite znova ali pa se obrnite na našo podporno ekipo!"
          );
          setErrorTitle("NAPAKA!");
        } else {
          setIsLoading(false);
          setError(true);
          setErrorText(error?.response?.data?.message);
          setErrorTitle(ErrorCodesParser(error?.response.data?.errorCode));
        }
      }
    } else {
      setIsLoading(false);
    }
  };

  // #region change password
  const changePassword = async () => {
    if (
      !PASSWORD_REGEX.test(userPassword.newPassword) ||
      !PASSWORD_REGEX.test(userPassword.oldPassword)
    ) {
      setError(true);
      setErrorText(
        "Geslo je v nepravilnem formatu vsebovati mora 1 veliko črko vsaj 8 znakov, vsaj 1 poseben znak in vsaj 1 številko"
      );
      setErrorTitle("GESLO UPORABNIKA");
      return;
    }

    let email = oldUser.email;

    if (user?.basic?.email) {
      email = user?.basic?.email;
    }

    setMailEmail(email);

    try {
      const response = await changePasswordApi(
        userPassword.oldPassword,
        userPassword.newPassword
      );

      if (response.data?.passwordChangeStatus) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        window.location.reload();
      }
    } catch (error) {
      if (!error.response) {
        setErrorText(
          "Pri nalaganju podatkov je prišlo do napake poskusite znova ali pa se obrnite na našo podporno ekipo!"
        );
        setErrorTitle("NAPAKA!");
      } else {
        setIsLoading(false);
        setError(true);
        setErrorText(error?.response?.data?.message);
        setErrorTitle(ErrorCodesParser(error?.response.data?.errorCode));
      }
    }
  };

  // #region forgoten password
  const submitForgotenPassword = async () => {
    if (!PASSWORD_REGEX.test(forgottenPassword.newPassword)) {
      setError(true);
      setErrorText(
        "Geslo je v nepravilnem formatu vsebovati mora 1 veliko črko vsaj 8 znakov, vsaj 1 poseben znak in vsaj 1 številko"
      );
      setErrorTitle("GESLO UPORABNIKA");
      return;
    }

    let email = oldUser.email;

    if (user?.basic?.email) {
      email = user?.basic?.email;
    }

    setMailEmail(email);

    try {
      const response = await forgottenPasswordApi(
        forgottenPassword.newPassword,
        email
      );
      if (response.data?.codeStatus === 1) {
        setIsLoading(false);
        setOpenEnterCodeWindow(true);
      }
    } catch (error) {
      if (!error.response) {
        setErrorText(
          "Pri nalaganju podatkov je prišlo do napake poskusite znova ali pa se obrnite na našo podporno ekipo!"
        );
        setErrorTitle("NAPAKA!");
      } else {
        setIsLoading(false);
        setError(true);
        setErrorText(error?.response?.data?.message);
        setErrorTitle(ErrorCodesParser(error?.response.data?.errorCode));
      }
    }
  };

  // #region JSX
  return (
    <div className="user-administration-page">
      {isLoading && <Loader />}
      {error && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={handleErrorWindow}
          btntext={"RAZUMEM"}
        />
      )}

      {!error && !isLoading && openEnterCodeWindow && (
        <VerifyEmail
          email={mailEmail}
          handleverifyemailwindow={handleCodeWindow}
          changeemail={false}
          password={forgottenPassword.newPassword}
        />
      )}
      <Header />
      <div className="user-administration-main">
        <div className="introduction">
          <ProfileIcon className="user-administration-profile-icon" />
          <span className="">{`${oldUser?.firstName} ${oldUser?.lastName}`}</span>
        </div>
        <div className="user-data-box">
          <span>E-naslov: {oldUser?.email}</span>
          <span>Število podjetij: {oldUser?.numberOfCompanies}</span>
          <span>Število ponudb: {oldUser?.numberOfOffers}</span>
        </div>
        <div className="change-data-box">
          <InputBox
            label="Ime "
            id="input-bar-1"
            class="input-bar"
            labelclass="label"
            inputcontainer="input-container-multiple"
            name="firstName"
            type="text"
            datatype="basic"
            onchange={handleInputChange}
            defaultvalue={oldUser?.firstName}
          />
          <InputBox
            label="Priimek "
            id="input-bar-2"
            class="input-bar"
            labelclass="label"
            inputcontainer="input-container-multiple"
            name="lastName"
            type="text"
            datatype="basic"
            onchange={handleInputChange}
            defaultvalue={oldUser?.lastName}
          />
          <InputBox
            label="E-naslov "
            id="input-bar-3"
            class="input-bar"
            labelclass="label"
            inputcontainer="input-container-multiple"
            name="email"
            type="text"
            datatype="basic"
            onchange={handleInputChange}
            defaultvalue={oldUser?.email}
          />
        </div>
        <div className="user-admnistration-submit-data-button-box">
          <button onClick={changeUsersData} className="button-1">
            SPREMENI
          </button>
        </div>
        <span className="section-title">SPREMENI GESLO </span>
        <div className="change-password-box">
          <InputBox
            label="Novo geslo "
            placeholder="Vnesi novo geslo..."
            id="input-bar-4"
            class="input-password"
            labelclass="label"
            inputcontainer="input-container-multiple"
            name="newPassword"
            type={showNewPassword ? "text" : "password"}
            showpassword={() => setShowNewPassword(!showNewPassword)}
            datatype="password"
            onchange={handleInputChange}
          />
          <InputBox
            label="Staro geslo "
            placeholder="Vnesi staro geslo..."
            id="input-bar-5"
            class="input-password"
            labelclass="label"
            inputcontainer="input-container-multiple"
            name="oldPassword"
            type={showOldPassword ? "text" : "password"}
            showpassword={() => setShowOldPassword(!showOldPassword)}
            datatype="password"
            onchange={handleInputChange}
          />
        </div>
        <div className="user-admnistration-submit-data-button-box">
          <button onClick={changePassword} className="button-1">
            SPREMENI
          </button>
        </div>
        <span className="section-title">POZABLJENO GESLO</span>
        <div className="change-password-box">
          <InputBox
            label="Novo geslo "
            placeholder="Vnesi novo geslo..."
            id="input-bar-6"
            class="input-password"
            labelclass="label"
            inputcontainer="input-container-multiple"
            name="newPassword"
            type={showForgotenPassword ? "text" : "password"}
            showpassword={() => setShowForgotenPassword(!showForgotenPassword)}
            datatype="forgottenPassword"
            onchange={handleInputChange}
          />
        </div>
        <div className="user-admnistration-submit-data-button-box">
          <button onClick={submitForgotenPassword} className="button-1">
            SPREMENI
          </button>
        </div>
      </div>
      <div></div>
      <Footer />
    </div>
  );
};

export default UserAdministration;
