import React, { useEffect, useState } from "react";
import "../Assets/Styles/Components/Sidebar.css";
import { Link } from "react-router-dom";

import { BsPersonCircle as ProfileIcon } from "react-icons/bs";
import { BsXLg as CloseIcon } from "react-icons/bs";

const Sidebar = (props) => {
  const [showPageClass, setShowPageClass] = useState("");
  const [showBoxClass, setShowBoxClass] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
        setShowPageClass(props.showsidebar ? "show-page" : "");
        setShowBoxClass(props.showsidebar ? "show-sidebar" : "");
    }, 10);

    return () => clearTimeout(timeout)
  }, [props.showsidebar]);

  const handleSidebar = (e) => {
    if(e.target.className !== "other-links-box"){
      props.closesidebar();
    }
  }

  return (
    <div className={`header-sidebar-page ${showPageClass}`} onClick={handleSidebar}>
      <div
        className={`header-sidebar-box ${showBoxClass}`}
      >
        <CloseIcon
          onClick={props.closesidebar}
          className="sidebar-close-icon"
        />
        <div className="login-company-box">
          <Link className="sidebar-link" to={"/prijavaPodjetja"}>
            Prijava podjetja
          </Link>
        </div>
        <div className="other-links-box">
          {/* TODO: DOdoaj, ponudba, politika zasebnosti, kontakt */}
          <Link className="sidebar-other-link" to={"/podjetje"}>
            Podjetja
          </Link>
          <Link className="sidebar-other-link" to={"/prijavaUporabnika"}>
            Prijava
          </Link>
          <Link  to={"/upravljanjePodjetja"} className="sidebar-other-link">Upravljanje podjetja</Link>
          <Link className="sidebar-other-link">Ponudba</Link>
          <Link to={"/kontakt"} className="sidebar-other-link">Kontakt</Link>
          <Link to={"/politikaZasebnosti"} className="sidebar-other-link">Politika zasebnosti</Link>
          <Link to={"/odkrijNas"} className="sidebar-other-link">Odkrij nas</Link>
        </div>
        <div className="user-profile-box">
          {/* TODO: Dodaj upravljanje uporabnika */}
          <Link
            className="user-profile-link"
            to={
              props.logginrequired
                ? "/prijavaUporabnika"
                : "/upravljanjeUporabnika"
            }
          >
            <ProfileIcon className="sidebar-profile-icon" />
            {props.logginrequired
              ? "Prijava"
              : `${props.user.firstName} ${props.user.lastName}`}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
