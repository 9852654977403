import React, { useEffect, useState } from "react";
import { DIACRITICS, SPECIAL_CHARS } from "../Constants/RegexPatterns";

import "../Assets/Styles/Pages/Offer.css";
import OfferCategoryParser from "../Components/utils/OfferCategoryParser";

const Offer = (props) => {
  const [imageName, setImageName] = useState(undefined);
  const [offerType, setOfferType] = useState("");

  useEffect(() => {
    setImageName(
      `${props.companyid}-${props.offer.basic.name
        .toLowerCase()
        .replace(DIACRITICS, "")
        .replace(SPECIAL_CHARS, "")}-${props.offer.basic.offerType}`
    );

    props.offer.basic.offerType === "service"
      ? setOfferType("storitve")
      : setOfferType("izdelka");

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // #region HANDLE DEFAULT IMAGE
  const handleDefaultImage = (e) => {
    e.target.src = "/addImageIcon.svg";
  };

  return (
    <div className="offer-site-page">
      <div className="offer-intro-page">
        <img
          src={`${process.env.REACT_APP_OFFER_IMAGES_URL}${imageName}.png`}
          alt="Slika ponudbe podjetja!"
          onError={(e) => handleDefaultImage(e)}
        />
      </div>
      <div className="offer-data-page">
        <div className="offer-description-text">{props.offer.basic.text}</div>
        <div className="offer-data-box">
          <div className="row-1">
            <span>
              {" "}
              <span className="title-text">Ime {offerType}:</span>{" "}
              {props.offer.basic.name}
            </span>
            <span>
              <span className="title-text">Cena {offerType}:</span>{" "}
              {props.offer.basic.price}
            </span>
            {offerType && offerType === "storitve" ? (
              <span>
                <span className="title-text">Čakalna vrsta {offerType}:</span>{" "}
                {props.offer.basic.time}
              </span>
            ) : (
              <span>
                <span className="title-text">Čas dostave {offerType}:</span>{" "}
                {props.offer.basic.time}
              </span>
            )}
          </div>
          <div className="row-2">
            <span>
              <span className="title-text">Informacije glede {offerType}:</span>{" "}
              {props.offer.basic.email}
            </span>
            <span>
              <span className="title-text">Kategorija {offerType}:</span>{" "}
              {OfferCategoryParser(props.offer.basic.category)}
            </span>
            <span>
              <span className="title-text">Vrsta {offerType}:</span>{" "}
              {props.offer.basic.type}
            </span>
            {props.offer.bool.warranty === 1 && (
              <span>
                <span className="title-text">Čas garancije {offerType}:</span>
                {props.offer.basic.warrantyTime}
              </span>
            )}
          </div>
          {props.offer.bool.returnItem === 1 && (
            <div className="return-box">
              <div className="row-3">VRAČILO IZDELKA</div>
              <div className="row-4">{props.offer.basic.returnConditions}</div>
            </div>
          )}
        </div>
      </div>

      {props.offer.bool.orderItem === 1 && (
        <div className="button-box">
          <button className="button-1" type="button">
            NAROČI
          </button>
        </div>
      )}
    </div>
  );
};

export default Offer;
