import React, { useEffect, useState } from "react";

import "../Assets/Styles/Components/DeleteCompanyWindow.css";

import getCompanyDeleteDataApi from "../Services/getCompanyDeleteData";
import deleteCompanyApi from "../Services/deleteCompany";

import legalFromParser from "./utils/LegalFormParser";
import regionParser from "./utils/RegionParser";
import categoryParser from "./utils/CategoryParser";
import WarningWindow from "./WarningWindow";
import ErrorWindow from "./ErrorWindow";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";


const DeleteCompanyWindow = (props) => {
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = originalStyle;
    };
  });

  const [companiesArr, setCompaniesArr] = useState([]);
  useEffect(() => {
    const getCompanyDeleteData = async () => {
      try {
        const response = await getCompanyDeleteDataApi(props.id);
        setCompaniesArr(response.data.companies);
      } catch (err) {
        setError(true);
        setErrorText(err?.response?.data?.message);
        setErrorTitle(ErrorCodesParser(err?.response?.data?.errorCode));
      }
    };

    getCompanyDeleteData();
  }, [props.id]);

  const [showWarning, setShowWarning] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);

  const handleWarning = (id) => {
    setDeleteId(id);
    setShowWarning(true);
  };

  const deleteCompany = async () => {
    if (deleteId) {
      try {
        props.closedeletecompanywindow();
        await deleteCompanyApi(deleteId);
      } catch (error) {
        setError(true);
        setErrorText(error?.response?.data?.message);
        setErrorTitle(ErrorCodesParser(error?.response?.data?.errorCode));
      }
    }
  };

  const handleErrorWindow = () => {
    setError(!error);
  };

  return (
    <div className="delete-company-page">
      {error && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={handleErrorWindow}
          btntext={"RAZUMEM"}
        />
      )}

      {showWarning && (
        <WarningWindow
          title="BRISANJE PODJETJA"
          text="Ali ste prepričani, da želite izbrisati podjetje?"
          gobackbtn="Prekliči"
          proceedbtn="Izbriši"
          goback={() => setShowWarning(!showWarning)}
          proceed={deleteCompany}
        />
      )}
      <div className="delete-company-box">
        <div className="delete-company-list">
          {companiesArr &&
            companiesArr.map((value, key) => (
              <div className="delete-company-list-item" key={key}>
                  <span>{value.shortName}</span>
                  <span>{value.taxNumber}</span>
                  <span>{value.registrationNumber}</span>
                  <span>{legalFromParser(value.legalForm)}</span>
                  <span>{categoryParser(value.category)}</span>
                  <span>{regionParser(value.region)}</span>
                <button className="button-2" type="button" onClick={() => handleWarning(value.ID)}>
                  Zbriši
                </button>
              </div>
            ))}
        </div>
        <div>
          <button className="button-1" type="button" onClick={props.closedeletecompanywindow}>
            Zapri
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteCompanyWindow;
