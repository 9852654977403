import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

import "../Assets/Styles/Pages/AboutUs.css";
const AboutUs = () => {
  return (
    <>
      <Header />
      <div className="aboutus-page">
        <div className="aboutus-box">
          <span className="aboutus-title">Opis</span>
          <span className="aboutus-text">
            Odkrij.si je poslovni register, ki vključuje splošne in kontaktne
            podatke podjetij. Vsak poslovni subjekt ima možnost dodajanja
            izdelkov ali storitev, ki jih lahko uporabniki naročijo ali pa jih
            pregledajo zgolj informativno.
          </span>
        </div>
        <div className="aboutus-box">
          <span className="aboutus-title">Cilj</span>
          <span className="aboutus-text">
            Povezati vsa slovenska podjetja ter jim omogočiti dostop do spletne
            vizitke po ugodni ceni. Tako bodo vsi poslovni subjekti v Sloveniji
            enostavno dostopni in vidni na spletu. S tem bomo približali
            slovenska podjetja potrošnikom ter jim omogočili hiter vpogled v
            osnovne informacije o ponudbah, kar bo strankam olajšalo iskanje
            želenih storitev ali izdelkov. Hkrati bomo podjetjem omogočili lažji
            stik z drugimi podjetji ter spodbujali vzpostavljanje novih
            poslovnih partnerstev in sodelovanj.
          </span>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
